import React from 'react'
import './Productbox.css'

const Productbox = () => {
  return (
    <div className='product_container'>
        <div className='heading_product_container'>
            <span className='product_head'>Best Seller Products</span> <br></br> <span className="product_p_tag">There are many variations passages</span>
            
         </div>
     <div className='up_low_product_container'>   
       <div className='uper_container'>
           <div className='product1'>
            <div className='product1_img'><img src='https://demo.phlox.pro/shop-digital-2/wp-content/uploads/sites/274/2021/04/Group-10194-470x457.jpg'/></div>
             <div className='im_below-para'><span className='left_par_img_below'>Momentum Phone</span><span className='right_par_img_below'>$520</span></div>  
             <div className='text_below_para_img'>Game Console <br/> Control ...</div>
             <div className='product1_button'><button>Add to cart</button></div>
           </div>

           {/*Product 2   start  -------------------*/}

           <div className='product2'>
            <div className='product1_img'><img src='https://demo.phlox.pro/shop-digital-2/wp-content/uploads/sites/274/2021/04/Group-10193-470x457.jpg'/></div>
             <div className='im_below-para'><span className='left_par_img_below'>Rocky Mountain</span><span className='right_par_img_below'>$150</span></div>  
             <div className='text_below_para_img'>Game Console <br/> Control ...</div>
             <div className='product1_button'><button>Add to cart</button></div>
           </div>


           {/*Product 3   start  -------------------*/}

           <div className='product3'>
            <div className='product1_img'><img src='https://demo.phlox.pro/shop-digital-2/wp-content/uploads/sites/274/2021/04/Group-10201-470x457.jpg'/></div>
             <div className='im_below-para'><span className='left_par_img_below'>Multiroom 360</span><span className='right_par_img_below'>$420</span></div>  
             <div className='text_below_para_img'>Game Console <br/> Control ...</div>
             <div className='product1_button'><button>Add to cart</button></div>
           </div>


           {/*Product 4   start  -------------------*/}

           <div className='product4'>
            <div className='product1_img'><img src='https://demo.phlox.pro/shop-digital-2/wp-content/uploads/sites/274/2021/04/Group-10200-470x457.jpg'/></div>
             <div className='im_below-para'><span className='left_par_img_below'>MacBook Air</span><span className='right_par_img_below'>$600</span></div>  
             <div className='text_below_para_img'>Game Console <br/> Control ...</div>
             <div className='product1_button'><button>Add to cart</button></div>
           </div>


       </div>


           {/*Lower container  start------------------------- */}


           <div className='lower_container'>
           <div className='product1'>
            <div className='product1_img'><img src='https://demo.phlox.pro/shop-digital-2/wp-content/uploads/sites/274/2021/04/Group-10193-300x291.jpg'/></div>
             <div className='im_below-para'><span className='left_par_img_below'>Momentum Phone</span><span className='right_par_img_below'>$520</span></div>  
             <div className='text_below_para_img'>Game Console <br/> Control ...</div>
             <div className='product1_button'><button>Add to cart</button></div>
           </div>

           {/*Product 2   start  -------------------*/}

           <div className='product2'>
            <div className='product1_img'><img src='https://demo.phlox.pro/shop-digital-2/wp-content/uploads/sites/274/2021/04/Group-10197-470x457.jpg'/></div>
             <div className='im_below-para'><span className='left_par_img_below'>Rocky Mountain</span><span className='right_par_img_below'>$150</span></div>  
             <div className='text_below_para_img'>Game Console <br/> Control ...</div>
             <div className='product1_button'><button>Add to cart</button></div>
           </div>


           {/*Product 3   start  -------------------*/}

           <div className='product3'>
            <div className='product1_img'><img src='https://demo.phlox.pro/shop-digital-2/wp-content/uploads/sites/274/2021/04/Group-10199-470x457.jpg' /></div>
             <div className='im_below-para'><span className='left_par_img_below'>Multiroom 360</span><span className='right_par_img_below'>$420</span></div>  
             <div className='text_below_para_img'>Game Console <br/> Control ...</div>
             <div className='product1_button'><button>Add to cart</button></div>
           </div>


           {/*Product 4   start  -------------------*/}

           <div className='product4'>
            <div className='product1_img'><img src='https://demo.phlox.pro/shop-digital-2/wp-content/uploads/sites/274/2021/04/Group-10195-470x457.jpg'/></div>
             <div className='im_below-para'><span className='left_par_img_below'>MacBook Air</span><span className='right_par_img_below'>$600</span></div>  
             <div className='text_below_para_img'>Game Console <br/> Control ...</div>
             <div className='product1_button'><button>Add to cart</button></div>
           </div>


       </div>

       {/*end lower contaiern--------------------------------- */}

    </div>
    </div>
  )
}

export default Productbox
